import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch, setUser, setRedirectAfterLogin } from './store';
import { FullscreenSpinner } from './components/FullscreenSpinner';
import { getAuthUser } from './services';
import { App } from 'antd';

export const Root = () => {
  const dispatch = useAppDispatch();
  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const { pathname } = useLocation();
  const queryRef = useRef(pathname);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAuthUser();
        dispatch(setUser(response));
      } catch (error) {
        dispatch(setRedirectAfterLogin(queryRef.current));
      }
      setIsAuthChecking(false);
    })();
  }, [dispatch]);

  if (isAuthChecking) {
    return <FullscreenSpinner />;
  }
  return (
    <App>
      <Suspense fallback={<FullscreenSpinner />}>
        <Outlet />
      </Suspense>
    </App>
  );
};
